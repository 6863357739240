import styled from 'styled-components';
import {device} from '../../../theme'

export const HeroDesc = styled.div`
  font-family: 'SonsieOne';
  color: white;
  text-shadow: 2px 2px #aaaaaa;
  margin-top: 18px;
  margin-bottom: 20px;
  font-size: 1.8em;
  ${device.xsmall}{
    margin-top: 18px;
    font-size: 1.8em;
  }
  ${device.small}{
    margin-top: 20px;
    font-size: 2.1em;
  }
  ${device.medium}{
    margin-top: 30px;
    font-size: 2.5em;
  }
  ${device.xlarge}{
    margin-top: 30px;
    font-size: 3em;
  }
`;
