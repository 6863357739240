import React from 'react'
import {HeroDesc} from './hero-desc.stc'

const HeroDescription = () => {

    return (
        <HeroDesc>
        </HeroDesc>
    );

}
export default HeroDescription
